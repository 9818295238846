function OpenSignBoxReducer(state = false, { type, payload }: any) {
  // if (payload) {
  //   return true;
  // }
  // return false;
  switch (type) {
    case "open":
      return true;
    case "close":
      return false;
    default:
      return state;
  }

}
export default OpenSignBoxReducer;
