import { CartItem, SubProducts } from "types/product";
import { SubProductWithCartID } from "./CartActions";

const initialState: CartItem[] = [];

const CartReducers = (state = initialState, action: { type: string, payload: SubProductWithCartID }) => {
    const payload = action.payload;
    var increaseQty = (payload?.multiply || 1);
    switch (action.type) {
        case "increase": {
            const available = state.find(value => value.cartId === payload?.cartId);
            let cart: SubProductWithCartID[] = []
            if (available) {
                cart = state.map((value) => {
                    if (value.cartId === payload.cartId) {
                        return {
                            ...value,
                            qty: value?.qty ? value?.qty + increaseQty : increaseQty
                        }
                    }
                    return value;

                })
            } else {
                cart = [...state, { ...payload, qty: increaseQty }]
            }
            setCartInLocalStorage(cart);
            return cart;
        }
        case "decrease": {
            let cart: SubProductWithCartID[] = state.map((value) => {
                if (value.cartId === payload.cartId) {
                    return {
                        ...value,
                        qty: value?.qty - increaseQty
                    }
                }
                return value;
            }).filter((value) => !!value.qty);
            setCartInLocalStorage(cart);
            return cart;
        }
        case "RemoveItem": {
            let cart: SubProductWithCartID[] = state.filter((value) => value.id !== payload.id);
            setCartInLocalStorage(cart);
            return cart
        }
        case "RemoveAll": {
            setCartInLocalStorage([]);
            return [];
        }
        case 'addItemDirectly': {
            const available = state.find(value => value.cartId === payload?.cartId);
            if (available) {
                return state
            }
            const cart = [...state, payload];
            setCartInLocalStorage(cart);
            return cart;
        }
        default:
            return state;
    }
};
export default CartReducers;

export const setCartInLocalStorage = (data: SubProductWithCartID[]) => {
    localStorage.setItem('deliverActCart', JSON.stringify(data));
}
