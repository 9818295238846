import React from 'react'
import { Restaurant } from 'types/restaurant';

export interface RestaurantData extends Restaurant {
  id: number;
  slug: string;
  categories: any[];
  visible_on_webapp?: boolean;
  has_deliverect: boolean;
}

export interface RestaurantArticle {
  id: number;
  articlename: string;
  category: number;
  description: string;
  dineintax: number;
  image: string;
  ingredient: number[];
  limit: any[];
  options: any[];
  pickuptax: number;
  price: string;
  restaurant: number;
  sold_out: boolean;
}

export interface CustomerMsg {
  Customer_message: null | string;
}

export const SET_ALL_RESTAURANTS = 'SET_ALL_RESTAURANTS'
export const SET_CURRENT_RESTAURANT = 'SET_CURRENT_RESTAURANT'
export const SET_RESTAURANT_ARTICLES = 'SET_RESTAURANT_ARTICLES'
export const SET_MSG_TO_CUSTOMERS = 'SET_MSG_TO_CUSTOMERS'

export interface SetAllRestaurantsAction {
  type: typeof SET_ALL_RESTAURANTS;
  data: RestaurantData[];
}

export interface SetCurrentRestaurantAction {
  type: typeof SET_CURRENT_RESTAURANT;
  data: RestaurantData | null;
}

export interface SetRestaurantArticlesAction {
  type: typeof SET_RESTAURANT_ARTICLES;
  data: RestaurantArticle[];
}

export interface SetMSGToCutomers {
  type: typeof SET_MSG_TO_CUSTOMERS;
  data: CustomerMsg;
}

export type RetaurantActionTypes = SetAllRestaurantsAction | SetCurrentRestaurantAction | SetRestaurantArticlesAction | SetMSGToCutomers

export interface RestaurantState {
  allRestaurants: RestaurantData[];
  currentRestaurant: Restaurant | null;
  selectedRestaurantArticles: RestaurantArticle[];
  msgToCustomers: CustomerMsg | null;
}
