import { combineReducers } from 'redux'
import OpenSignUpBoxReducer from './OpenSignUpBoxReducer/OpenSignUpBoxReducer'
import restaurant from './restaurant/reducers'
import CartReducers from './cart/CartReducer'

export default combineReducers({
  restaurant,
  cart: CartReducers,
  isOpen: OpenSignUpBoxReducer,
})
