import React, { Suspense, useEffect } from 'react'
import { Route, Switch, Router } from 'react-router-dom'
import { FadeLoader } from 'react-spinners'
import { css } from '@emotion/react'
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary'
import history from './history'
import i18n from "i18next";
import { Trans, initReactI18next, useTranslation } from 'react-i18next';
import { localization } from "i18n/index";
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentRestaurantSelector } from 'store/reducers/restaurant/selectors'
import { AddItemDirectly, SubProductWithCartID } from 'store/reducers/cart/CartActions'
// import UserProfile from 'views/UserProfile'
//@ts-ignore
i18n.use(initReactI18next).init({ ...localization, lng: localStorage.getItem('yopla_lang') || "Dutch" });
const Profile = React.lazy(() => import('./views/Profile'))
const SearchResult = React.lazy(() => import('./views/SearchResult'))
const RegisterPage = React.lazy(() => import('./views/Registration'))
const CheckoutPage = React.lazy(() => import('./views/CheckoutPage'))
const ForgotPassword = React.lazy(() => import('./views/ForgotPassword'))
const ResetPassword = React.lazy(() => import('./views/ResetPassword'))
const ResetPass = React.lazy(() => import('./views/ResetPass'))
const Home = React.lazy(() => import('./views/Home'))
const PreviousOrdersPage = React.lazy(() => import('./views/PreviousOrdersPage'))
const OrderDetail = React.lazy(() => import('./views/OrderDetailPage'))
const TestQr = React.lazy(() => import('./TestQr'))
const CartPage = React.lazy(() => import('./views/CartPage'))
const RestaurantByTable = React.lazy(() => import('./views/RestaurantByTable'))
const ConfirmPage = React.lazy(() => import('./views/ConfirmOrderPage'))
const Page404 = React.lazy(() => import('./views/Page404'))
const TermsAndConditions = React.lazy(() => import("./components/Footer/TermsAndCondition"))
const PrivacyStatement = React.lazy(() => import("./components/Footer/PrivacyStatement"))
const CookieStatement = React.lazy(() => import("./components/Footer/CookieStatement"))
const AboutUs = React.lazy(() => import("./components/Footer/AboutUs"))
const Contact = React.lazy(() => import("./components/Footer/Contact"))
const UserProfile = React.lazy(() => import("views/UserProfile"))
const RestaurantAgreement = React.lazy(() => import("./components/Footer/RestaurantAgreement"))


function App() {
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: #be0a27;
    margin-top: 20rem;
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: transparent;
  `
  const fallbackUI = () => (
    <FadeLoader css={override} width={5} height={15} margin="2px" color="#08a19a" />
  )

  React.useEffect(() => {
    const root = document.getElementById('root')

    if (root) {
      const childs = Array.from(root.children);
      // console.clear();
      if (childs.length === 0) {
        // window.location.href = '/notfound';
      }
    }
  }, []);
  // store data in cart
  const dispatch = useDispatch();
  const restaurantData = JSON.parse(localStorage.getItem('restaurant_data') || '{}');
  useEffect(() => {
    if (restaurantData?.id) {

      getAllProductFromCart();
    }
  }, [restaurantData?.id]);
  const getAllProductFromCart = () => {
    // get all cart detail from local adn remove items so we can store updated items again
    const localCart = JSON.parse(localStorage.getItem('deliverActCart') || "[]") as SubProductWithCartID[];
    localStorage.removeItem('deliverActCart');
    localCart?.forEach(product => {
      if ((restaurantData?.id || '') === product?.restaurantId) {
        dispatch(AddItemDirectly(product));
      }
    })
  }
  // end store data in cart
  return (
    <>
      <ErrorBoundary>
        <Router history={history}>
          <Suspense fallback={fallbackUI()}>
            <Switch>
              <Route exact={true} path="/" component={Home} />
              <Route exact={true} path="/search/:zipCode" component={SearchResult} />
              <Route exact={true} path="/order" component={OrderDetail} />
              <Route exact={true} path="/home" component={PreviousOrdersPage} />
              <Route exact={true} path="/register" component={RegisterPage} />
              <Route exact={true} path="/cartpage" component={CartPage} />
              <Route exact={true} path="/checkout" component={CheckoutPage} />
              <Route exact={true} path="/confirmpage" component={ConfirmPage} />
              <Route exact={true} path="/restaurantAgreement" component={RestaurantAgreement} />
              <Route exact={true} path="/restaurant/:restaurantSlug" component={Profile} />
              <Route
                exact={true}
                path="/restaurant/:restaurantSlug/:tableId"
                component={Profile}
              />
              <Route
                exact={true}
                path={'/:restaurantId/:tblId(\\d+)'}
                component={RestaurantByTable}
              />

              <Route exact={true} path="/qr" component={TestQr} />
              <Route
                path="/reset_password/:restid/forgotPasswordReset/:code"
                component={ResetPass}
              />
              <Route exact={true} path="/forgot_password" component={ForgotPassword} />
              <Route exact={true} path="/confirm_password" component={ResetPassword} />
              <Route exact={true} path="/notfound" component={Page404} />
              <Route exact={true} path="/terms_and_conditions" component={TermsAndConditions} />
              <Route exact={true} path="/privacy_statement" component={PrivacyStatement} />
              <Route exact={true} path="/cookie_statement" component={CookieStatement} />
              <Route exact={true} path="/about_us" component={AboutUs} />
              <Route exact={true} path="/contact" component={Contact} />
              <Route exact={true} path="/user/profile" component={UserProfile} />

            </Switch>
          </Suspense>
        </Router>
      </ErrorBoundary>
    </>
  )
}
export default App
