export const localization = {
    resources: {
        English: {
            translation: {
                home: {
                    home_title: "Home",
                    home_banner_title: "Eatsy, Eat great order Easy",
                    // home_search_label: "Vind de beste restaurants in de buurt",
                    home_search_label2: "Please enter your zip code",
                    // home_search_button_title: "Zoeken",
                    home_search_validation_empty: "Postal code can't be empty.",
                    // home_search_input_placeholder: "Geef postcode in",
                    home_search_result_title: "Search Results",
                    home_search_result_sub_title: "Based on your selected location (postal code)",
                    home_search_num_record_found: "Results so far",
                    // home_banner_order_step_location: "Kies locatie",
                    // home_banner_order_step_place_order: "Plaats je bestelling",
                    // home_banner_order_step_delivery_option: "Laat leveren of haal af",
                    // home_list_restaurant_title: "restaurants in je buurt ",
                    // home_list_restaurant_category_title: "Populair in je buurt", 
                    // home_list_restaurant_category_sub_title: "Selecteer je restaurant en bestel",
                    // home_order_steps_title: "Eenvoudig bestellen in 3 stappen",
                    // home_order_step1_title: "Kies een restaurant",
                    // home_order_step1_sub_title: "Is je favoriete restaurant niet online? Beveel hem aan",
                    // home_order_step2_title: "Kies je favoriete gerechten",
                    // home_order_step2_sub_title: "Maak keuze uit de heerlijke gerechten van je favoriete restaurant.",
                    // home_order_step3_title: "Kies om zelf af te halen of te laten leveren", 
                    // home_order_step3_sub_title: "Sommige zaken hebben ook een eigen leverdienst. Maak er gerust gebruik van.",
                    // home_order_payment_info: "Betaal veilig online via Mollie online betalingen.",
                    // home_trader_banner_title: "Handelaar?",
                    // home_trader_banner_info: "Bent u handelaar en wenst u ook gebruik te maken van het Eatsy online bestelplatform? Klik hier.", 
                    // home_trader_banner_button_title: "Meer informatie",
                },
                footer: {
                    // site_application_info_title: "Wist je dat wij ook een app hebben?",
                    // site_application_info_sub_title: "Bestel je vaak en mobiel? Download dan zeker onze app. Ontdek hem voor zowel IOS als Android via onderstaande knoppen.",
                    // title: "Over ons ",
                    // site_description: "Eatsy is een online bestelplatform waarmee handelaars hun producten online kunnen aanbieden aan een zeer voordelig tarief. Door gebruik te maken van Eatsy steunt u dan ook de horecasector. Meer informatie vind je <a href='https://eatsy.be' target='_blank' rel='noreferrer'>hier</a>.",
                    // trader_title: "Handelaar?",
                    // trader_info: " U bent handelaar en wenst gebruik te maken van het Eatsy platform? Klik hier <a href='https://eatsy.be' target='_blank' rel='noreferrer'>Eatsy</a>.",
                    // payment_options: "Betalingsmogelijkheden",
                    text_smart_solutions: "Smart Ordering Solutions",
                    text_contact: "Contac",
                },
                login: {
                    // title: "Log in of maak een account aan",
                    email_label: "E-mail",
                    email_placeholder: "E-mail",
                    // password_label: "Wachtwoord",
                    // password_placeholder: "Wachtwoord",
                    validation_email_empty: "Please fill email field.",
                    validation_email_not_valid: "Please enter valid email.",
                    validation_password_empty: "Please fill password field.",
                    button_login: "Login",
                    // navigate_register: "Geen account? <a href="#" className="registerlink">Maak nu een account aan.</a>",
                    // navigate_forget_password: " Wachtwoord vergeten?<a href="#" className="registerlink" >Klik hier</a>",
                },
                register: {
                    // title: "Geen account",
                    // label_firstName: "Voornaam",
                    // label_lastName: "Achternaam",
                    label_email: "E-mail",
                    label_phone: "GSM",
                    // label_street_and_house_number: "Straat en huisnummer (Zal gebruikt worden indien levering)",
                    // label_zipcode_and_municipality: "Postcode & gemeente",
                    // label_password: "Wachtwoord",
                    // label_confirm_password: "Bevestig wachtwoord",
                    button_navigation: "Existing User?",
                    validation_firstname_empty: "Please fill First Name field.",
                    validation_lastname_empty: "Please fill Last Name field.",
                    validation_email_empty: "Email name can't be empty.",
                    validation_email_not_valid: "Please enter valid email.",
                    validation_phone_not_valid: "Please enter phone number in valid form.",
                    validation_address_empty: "Please fill address field.",
                    validation_city_state_empty: "Please fill state/city field.",
                    validation_password_length: "Password length should be 6 or more.",
                    validation_confirm_password_equality: "Confirm Password is not matched.",
                    button_register: "Register",
                    button_login: "Login",

                },
                // forgotPassword: {
                //     title: "Vul hieronder uw e-mail in. We versturen een verzoek tot herstel.",
                //     button_send: "Verstuur",
                // },
                menuBar: {
                    title_login: "Log in",
                    // menu_title_logout: "Uitloggen",
                    // menu_title_previous_orders: "Vorige bestellingen",
                },
                restaurantDetail: {
                    restaurant_status_offline: "OFFLINE",
                    restaurant_status_online: "ONLINE",
                    restaurant_status_Open: "Open",
                    restaurant_status_close: "Close",
                    text_no_category: "No Categories",
                    text_select_option: "Please select option.",
                    validation_greatedThenZero: "Selected option must have price greater than zero.",
                    requestAcceptMsg: 'Request accepted !',
                    somethingWrongMsg: 'Something wrong !',
                    invoiceRequestMsg: 'Invoice request process !',
                    selectOptionErrorMsg: 'Please select option according to  min and max'
                    // left_sidebar_title: "Maak uw keuze",
                    // left_sidebar_menu_allProducts: "Alle producten",
                    // right_sidebar_title: "Huidige bestelling",
                    // right_sidebar_buttton_continue: "Ga verder",
                    //  right_sidebar_text_total: "Totaal",

                },
                addItem: {
                    // addItem_button_close_label: "Sluit",
                    // addItem_button_order_now: "Bestel nu",
                },
                cart: {
                    title: "Your Shopping Cart",
                    // quantity: "Aantal",
                    total: "TOTAL",
                    cart_navigate_checkout: "Checkout",
                    confirm_button_yes: "Yes",
                    confirm_button_close: "close",
                    confirm_clear_cart: "Are you sure to clear cart",
                },
                checkout: {
                    title: "Checkout",
                    // navigate_login:'<span>Vul onderstaand formulier in of</span><a href="#" ><span> login om verder </span></a> <span>te gaan.</span>',
                    // label_firstName: "Voornaam",
                    // label_lastName: "Achternaam",
                    label_email: "E-mail",
                    label_phone: "GSM",
                    // quantity: "Aantal",
                    // order_detail_title: "Uw bestelling",
                    label_deliveryFee: "Delivery Fee",
                    label_fixedCost: "Fixed cost",
                    label_serviceCost: "Service Cost",
                    // label_total: "Totaal",
                    // select_delivery_date: "Kies gewenste datum en tijdstip",
                    // payon_site_label: "Betaal ter plaatse of bij levering",
                    // button_payOnline: "Betaal online",
                    // button_payOnline_sub_text: "(U wordt doorverbonden met Mollie na bevestigen van de bestelling)",
                    // delivery_option_title: "Kies levering aan huis of afhalen ter plaatse.",
                    // delivery_option_home: "Levering aan huis",
                    // delivery_option_pickUpOnSite: "Afhalen ter plaatse", 
                    // checkout_delivery_notprovide: "Deze zaak voorziet geen lever optie op dit moment",
                    checkout_pickup_notprovide: "PickUp option is not available",
                    // postal_code: "Postcode & gemeente",
                    placeholder_postalcode: "Please select pincode from dropdown",
                    // street_address: "Straat en huisnummer (Zal gebruikt worden indien levering)",
                    // comment: "Opmerkingen",
                    // placeholder_comment: "Vermeld hier opmerkingen omtrent uw bestelling",
                    validation_firstname_empty: "Please fill First Name field.",
                    validation_lastname_empty: "Please fill Last Name field.",
                    validation_email_empty: "Please fill email field.",
                    validation_email_not_valid: "Please enter valid email.",
                    validation_phone_not_valid: "Please enter phone number in valid form.",
                    validation_password_length: "Password length should be 6 or more.",
                    validation_confirm_password_equality: "Confirm Password is not matched.",
                    register_button_register: "Register",
                    register_button_login: "Login",
                    validation_delivery_date_empty: "Select delivery date and time!",
                    validation_pincode_empty: "Please select pincode for delivery",
                    validation_order_type: "Please select delivery type",
                    // button_confirm_order: "Bevestig bestelling",
                    orderPlacedMsg: 'Your Order Placed successfully!',
                    DeliveryPickUpMessage: 'Delivery and pickup option available for time interval',//remaining 
                    emptyDeliveryTime: "You didn't select delivery time!",//remaining 
                    continueGuestCheckboxLabel: "Continue as guest"
                },
                previousOrder: {
                    // title: "Vorige bestellingen",
                    // order_number: "Bestelling",
                    // order_status: "Status bestelling",
                    // status_pending: "In afwachting",
                    // status_accepted: "Geaccepteerd",
                    // status_cancel: "Geannuleerd", 
                    // date_label: "<span >Besteld </span>voor",
                    total_price: "Total Paid",
                    // button_reOrder: "Bestel opnieuw",
                    // button_navigate_order_details: "Bekijk bestelling", 
                    no_prev_order: "You didn't order anything yet. "

                },
                orderAgain: {
                    title: "Please Choose Delivery Date/Time",
                    delivery_date_empty: "Please select delivery date/time",
                    info_message: "We are currently closed. We will back soon.",
                    confirm_message: "Sure want to Re-order",
                    dropdown_placeholder: "select time",
                    dropdown_empty: "There are no items in this list",
                    button_close: "close",
                    button_submit: "submit",
                },
                previousOrderDetail: {
                    title: "Your Order Detail",
                    order_number: "Order Number",
                    order_date: "Order Date",
                    user_note: "User Note",
                    price: "Price",
                    delivery_price: "Delivery",
                    service_price: "Service",
                    // total_price: "Totaal",
                    quantity: "Quantity",//done
                },
                page404: {
                    title: 'Page not found',
                    desc: "Unfortunately the page you’re looking for isn’t here.",
                    navigate_home: "Return To Home"
                },
                weekday: {
                    mon: "Monday",
                    tue: "Tuesday",
                    wed: "Wednesday",
                    thu: "Thursday",
                    fri: "Friday",
                    sat: "Saturday",
                    sun: "Sunday",
                },
                profile: {
                    title: 'Update Profile',
                    validation: {
                        firstNameRequired: 'Please enter first name',
                        lastNameRequired: 'Please enter last name',
                        addressRequired: 'Please enter address.',
                        phoneRequired: 'Please enter phone number.',
                        phoneInvalid: "Please enter valid phone number",
                        userUpdated: "User information updated successfully.",
                        userNotUpdated: "Unable to update User information. please try again.",
                        userDelete: "User is deleted.",
                        userNotDelete: "Unable to delete this user. please try again."
                    },
                    updateButtonLabel: 'Update',
                    deleteButtonLabel: 'Delete',
                    firstNameLabel: 'First Name',
                    firstNamePlaceholder: 'Enter first name',
                    lastNameLabel: 'Last Name',
                    lastNamePlaceholder: 'Enter last name',
                    phoneLabel: 'Phone',
                    phonePlaceholder: 'Enter phone number',
                    addressLabel: 'Address',
                    addressPlaceholder: 'Enter address'
                },
                OrderReceipt: {
                    title1: 'Merci de votre commande',
                    title2: 'Votre paiement est en cours de vérification!',
                    description: "Vous recevrez bientôt une confirmation de votre commande dans votre boîte mail. Merci pour votre commande et à bientôt.",
                    NumberOfArticles: "Nombre d'articles #",
                    tableNumber: "Numéro de tableau"
                }
            }
        },
        Dutch: {
            translation: {
                header: {
                    customerLoginButtonLabel: 'Aanmelden',
                    MerchantLoginButtonLabel: 'Aanmelden handelaar'
                },
                home: {
                    home_title: "Home",
                    home_banner_title: "Eatsy, Eat great order Easy",
                    home_search_label: "Vind de beste restaurants in de buurt",
                    home_search_label2: "Voer uw postcode in",
                    home_search_button_title: "Zoeken",
                    home_search_validation_empty: "Postcode kan niet leeg zijn.",
                    home_search_input_placeholder: "Geef een postcode in",
                    home_search_result_title: "Zoekresultaten",
                    home_search_result_sub_title: "Op basis van uw geselecteerde locatie (postcode)",
                    home_search_num_record_found: "Resultaten tot nu toe",
                    home_banner_order_step_location: "Kies locatie",
                    home_banner_order_step_place_order: "Plaats je bestelling",
                    home_banner_order_step_delivery_option: "Laat leveren of haal af",
                    home_list_restaurant_title: "restaurants in je buurt ",
                    home_list_restaurant_category_title: "Populair in je buurt",
                    home_list_restaurant_category_sub_title: "Selecteer je restaurant en bestel",
                    home_order_steps_title: "Eenvoudig bestellen in 3 stappen",
                    home_order_step1_title: "Kies een restaurant",
                    home_order_step1_sub_title: "Is je favoriete restaurant niet online? Beveel het aan!",
                    home_order_step2_title: "Kies je favoriete gerechten",
                    home_order_step2_sub_title: "Maak keuze uit de heerlijke gerechten van je favoriete restaurant.",
                    home_order_step3_title: "Kies om zelf af te halen of te laten leveren",
                    home_order_step3_sub_title: "Sommige zaken hebben ook een eigen leverdienst. Maak er gerust gebruik van.",
                    home_order_payment_info: "Betaal veilig online via onze online betalingsmogelijkheden.",
                    home_trader_banner_title: "Handelaar?",
                    home_trader_banner_info: "Bent u handelaar en wenst u ook gebruik te maken van het Eatsy online bestelplatform?",
                    home_trader_banner_info_extension: "Klik hier.",
                    home_trader_banner_button_title: "Meer informatie",
                },
                footer: {
                    site_application_info_title: "Wist je dat wij ook een mobiele app hebben?",
                    site_application_info_sub_title: "Bestel je vaak en mobiel? Download dan zeker onze app. Ontdek hem voor zowel iOS als Android via onderstaande knoppen.",
                    title: "Over ons",
                    site_description: "Eatsy is een online bestelplatform waarmee handelaars hun producten online kunnen aanbieden aan een zeer voordelig tarief. Door gebruik te maken van Eatsy steunt u dan ook de horecasector. Meer informatie vind je <a  href='/#/contact' rel='noreferrer'>hier</a>.",
                    trader_title: "Handelaar?",
                    trader_info: "U bent handelaar en wenst gebruik te maken van het Eatsy platform? <a  href='/#/contact' rel='noreferrer'>Klik hier</a>.",
                    payment_options: "Betalingsmogelijkheden",
                    text_smart_solutions: "Smart Ordering Solutions",
                    text_contact: "Contact",
                    privacy_policy: "Privacybeleid",
                    cookie_statement: "Cookiebeleid",
                    terms_and_conditions: "Algemene Voorwaarden",
                },
                login: {
                    title: "Log in of maak een account aan",
                    email_label: "E-mail",
                    email_placeholder: "E-mail",
                    password_label: "Wachtwoord",
                    password_placeholder: "Wachtwoord",
                    validation_email_empty: "Voer een e-mailadres in.",
                    validation_email_not_valid: "Vul een geldig emailadres in.",
                    validation_password_empty: "Vul het wachtwoordveld in.",
                    button_login: "Login",
                    navigate_register: "Geen account? <a href='#' className='registerlink'>Maak een account aan.</a>",
                    navigate_forget_password: "Wachtwoord vergeten?<a href='#' className='registerlink' >Klik hier.</a>",
                },
                register: {
                    title: "Geen account",
                    label_firstName: "Voornaam",
                    label_lastName: "Achternaam",
                    label_email: "E-mail",
                    label_phone: "GSM",
                    label_street_and_house_number: "Straat en huisnummer (Zal gebruikt worden indien levering)",
                    label_zipcode_and_municipality: "Postcode & gemeente",
                    label_password: "Wachtwoord",
                    label_confirm_password: "Bevestig wachtwoord",
                    button_navigation: "Bestaande gebruiker?",
                    validation_firstname_empty: "Vul het veld voornaam in.",
                    validation_lastname_empty: "Vul het veld achternaam in.",
                    validation_email_empty: "E-mail mag niet leeg zijn.",
                    validation_email_not_valid: "Voer een geldig emailadres in.",
                    validation_phone_not_valid: "Geef een geldig telefoonnummer op.",
                    validation_password_length: "Wachtwoordlengte moet 6 karakters of meer zijn.",
                    validation_confirm_password_equality: "Wachtwoorden komen niet overeen.",
                    button_register: "Registreren",
                    button_login: "Login",
                    validation_address_empty: "Vul het adresveld in.",
                    validation_city_state_empty: "Vul het veld staat/stad in.",
                },
                forgotPassword: {
                    title: "Vul hieronder uw e-mail in. We versturen u nadien een verzoek tot herstel.",
                    button_send: "Verstuur",
                },
                menuBar: {
                    title_login: "Log in",
                    menu_title_logout: "Uitloggen",
                    menu_title_previous_orders: "Vorige bestellingen",
                    menu_title_profile: 'Profiel'
                },
                restaurantDetail: {
                    restaurant_status_offline: "Offline",
                    restaurant_status_online: "Online",
                    restaurant_status_Open: "Open",
                    restaurant_status_close: "Gesloten",
                    text_no_category: "Geen categorieën",
                    text_select_option: "Selecteer een optie",
                    validation_greatedThenZero: "De geselecteerde optie moet een prijs hebben die groter is dan nul.",
                    left_sidebar_title: "Maak uw keuze",
                    left_sidebar_menu_allProducts: "Alle producten",
                    right_sidebar_title: "Huidige bestelling",
                    right_sidebar_buttton_continue: "Ga verder",
                    right_sidebar_text_total: "Totaal",
                    openingHours: "Openingsuren", //remining
                    askForService: "Vraag om service", //remining
                    askForInvoice: "Vraag de rekening",//remining,
                    requestAcceptMsg: 'Verzoek geaccepteerd!',//remining,
                    somethingWrongMsg: 'Er is iets mis!',//remining,
                    invoiceRequestMsg: 'Factuuraanvraag proces!',//remining,
                    selectOptionErrorMsg: 'Selecteer de optie op basis van min en max'
                },
                addItem: {
                    button_close_label: "Sluit",
                    button_order_now: "Bestel nu",
                },
                cart: {
                    title: "U winkelwagen",
                    quantity: "Aantal",
                    total: "Totaal",
                    cart_navigate_checkout: "Afrekenen",
                    confirm_button_yes: "Ja",
                    confirm_button_close: "Sluiten",
                    confirm_clear_cart: "Weet u zeker dat u uw winkelwagen wilt wissen?",
                },
                checkout: {
                    title: "Afrekenen",
                    navigate_login: "<span>Vul onderstaand formulier in of</span><a href='#' ><span> login om verder </span></a> <span>te gaan.</span>",
                    label_firstName: "Voornaam",
                    label_lastName: "Achternaam",
                    label_email: "E-mail",
                    label_phone: "GSM",
                    quantity: "Aantal",
                    order_detail_title: "Uw bestelling",
                    label_deliveryFee: "Bezorgkosten",
                    label_fixedCost: "Vaste kosten",
                    label_serviceCost: "Servicekosten",
                    label_total: "Totaal",
                    select_delivery_date: "Kies gewenste leverdatum en tijdstip",
                    payon_site_label: "Betaal ter plaatse of bij levering",
                    button_payOnline: "Betaal online",
                    button_payOnline_sub_text: "(U wordt doorverbonden met het betaalplatform na bevestigen van de bestelling)",
                    delivery_option_title: "Kies levering aan huis of afhalen ter plaatse.",
                    delivery_option_home: "Levering aan huis",
                    delivery_option_pickUpOnSite: "Afhalen ter plaatse",
                    checkout_delivery_notprovide: "Deze zaak voorziet geen levering op dit moment",
                    checkout_pickup_notprovide: "Ophaaloptie is niet beschikbaar",
                    postal_code: "Postcode & gemeente",
                    placeholder_postalcode: "Selecteer jouw postcode in de vervolgkeuzelijst",
                    street_address: "Straat en huisnummer (Zal gebruikt worden indien levering)",
                    comment: "Opmerkingen",
                    placeholder_comment: "Vermeld hier opmerkingen omtrent uw bestelling",
                    validation_firstname_empty: "Vul het veld voornaam in.",
                    validation_lastname_empty: "Vul het veld achternaam in.",
                    validation_email_empty: "Vul het e-mailadres in.",
                    validation_email_not_valid: "Voer een geldig emailadres in.",
                    validation_phone_not_valid: "Voer het telefoonnummer in een geldige vorm in.",
                    validation_password_length: "Wachtwoordlengte moet 6 of meer zijn.",
                    validation_confirm_password_equality: "Bevestig dat het wachtwoord niet overeenkomt.",
                    register_button_register: "Registreren",
                    register_button_login: "Login",
                    validation_delivery_date_empty: "Kies bezorgdatum en -tijd!",
                    validation_pincode_empty: "Selecteer zipcode voor bezorging",
                    validation_order_type: "Selecteer het bezorgtype",
                    button_confirm_order: "Bevestig bestelling",
                    orderPlacedMsg: 'Uw bestelling succesvol geplaatst!',
                    DeliveryPickUpMessage: 'Bezorg- en ophaalopties zijn beschikbaar voor tijdsintervallen',//remaining 
                    emptyDeliveryTime: 'U heeft geen bezorgtijd geselecteerd!',//remaining 
                    orderConfirmationText: "Door te klikken op ‘Bevestig bestelling' stemt u in met de inhoud van uw winkelmandje, de verstrekte gegevens, privacybeleid en de algemene voorwaarden van zowel Eatsy als de Verkoper. Let op: er is geen mogelijkheid tot herroeping.",
                    continueGuestCheckboxLabel: "Ga door als gast",

                },
                previousOrder: {
                    title: " Vorige bestellingen ",
                    order_number: "Bestelling",
                    order_status: "Status bestelling",
                    status_pending: "In afwachting",
                    status_accepted: "Geaccepteerd",
                    status_cancel: "Geannuleerd",
                    date_label: "<span >Besteld </span>voor",
                    total_price: "Totaal betaald",
                    button_reOrder: "Bestel opnieuw",
                    button_navigate_order_details: "Bekijk bestelling",
                    validation_delivery_date_empty: "Selecteer bezorgdatum/-tijd",
                    no_prev_order: "Je hebt nog niets besteld."
                },
                orderAgain: {
                    title: "Kies de bezorgdatum/-tijd",
                    delivery_date_empty: "Selecteer bezorgdatum/-tijd",
                    info_message: "We zijn momenteel gesloten. We zullen snel terugkomen.",
                    confirm_message: "Ik wil zeker opnieuw bestellen",
                    dropdown_placeholder: "Tijd selecteren",
                    dropdown_empty: "Er zijn geen items in deze lijst",
                    button_close: "Sluiten",
                    button_submit: "submit",
                },
                previousOrderDetail: {
                    title: "Uw bestelgegevens",
                    order_number: "Bestellingsnummer",
                    order_date: "Besteldatum",
                    user_note: "User Note",
                    price: "Prijs",
                    delivery_price: "Levering",
                    service_price: "dienst",
                    total_price: "Totaal",
                    quantity: "Hoeveelheid",
                },
                page404: {
                    title: 'Pagina niet gevonden',
                    desc: "Helaas, kunnen we de pagina niet vinden.",
                    navigate_home: "Terugkeren naar huis"
                },
                weekday: {
                    mon: "Maandag",
                    tue: "Dinsdag",
                    wed: "Woensdag",
                    thu: "Donderdag",
                    fri: "Vrijdag",
                    sat: "Zaterdag",
                    sun: "Zondag",
                },
                profile: {
                    title: 'Profiel bijwerken',
                    validation: {
                        firstNameRequired: 'Vul het veld voornaam in.',
                        lastNameRequired: 'Vul het veld achternaam in.',
                        addressRequired: "Vul het adresveld in.",
                        phoneRequired: 'Voer een telefoonnummer in.',
                        phoneInvalid: "Voer een geldig telefoonnummer in",
                        userUpdated: "Gebruikersinformatie is succesvol bijgewerkt.",
                        userNotUpdated: "Kan de gebruikersinformatie niet bijwerken. Probeer het opnieuw.",
                        userDelete: "Gebruiker is verwijderd.",
                        userNotDelete: "Kan deze gebruiker niet verwijderen. Probeer het opnieuw."
                    },
                    updateButtonLabel: 'Update',
                    deleteButtonLabel: 'Verwijder account',
                    firstNameLabel: 'Voornaam',
                    firstNamePlaceholder: 'Vul voornaam in',
                    lastNameLabel: 'Achternaam',
                    lastNamePlaceholder: 'Voer achternaam in',
                    phoneLabel: 'GSM',
                    phonePlaceholder: 'Voer telefoonnummer in',
                    addressLabel: 'Straat en huisnummer (Zal gebruikt worden indien levering)*',
                    addressPlaceholder: 'Straat, nummer + bus',
                    pincodeLabel: 'Postcode & gemeente',
                    pincodePlaceholder: 'Selecteer Postcode in de vervolgkeuzelijst',
                    deleteConfirmDialog: {
                        cancelButtonLabel: "Annuleren",
                        deleteButtonLabel: 'Verwijder account',
                        message: "Wilt u uw account verwijderen?"
                    }
                },
                OrderReceipt: {
                    title1: 'Bedankt voor uw bestelling.',
                    title2: 'Uw betaling wordt gecontroleerd!',
                    description: "U ontvangt binnenkort een bevestiging van uw bestelling in uw mailbox. Bedankt voor uw bestelling en tot ziens.",
                    NumberOfArticles: "Aantal artikelen #",
                    tableNumber: "Tafelnummer"
                }
            }
        },
        French: {
            translation: {
                header: {
                    customerLoginButtonLabel: 'Se connecter',
                    MerchantLoginButtonLabel: 'Se connecter commerçant'
                },
                home: {
                    home_title: "Page d'accueil",
                    home_banner_title: "Eatsy, Eat great order Easy",
                    home_search_label: "Trouvez les meilleurs restaurants à proximité.",
                    home_search_label2: "Entrez votre code postal",
                    home_search_button_title: "Rechercher",
                    home_search_validation_empty: "Le code postal ne peut pas être vide.",
                    home_search_input_placeholder: "Entrez un code postal",
                    home_search_result_title: "Résultats de recherche",
                    home_search_result_sub_title: "En fonction de votre localisation (code postal) sélectionnée",
                    home_search_num_record_found: "Résultats jusqu'à présent.",
                    home_banner_order_step_location: "Choisissez votre emplacement",
                    home_banner_order_step_place_order: "Passez votre commande",
                    home_banner_order_step_delivery_option: "Livrer ou à Emportez",
                    home_list_restaurant_title: "restaurants près de chez vous",
                    home_list_restaurant_category_title: "Populaire près de chez vous",
                    home_list_restaurant_category_sub_title: "Selectionner un restaurant et commandez",
                    home_order_steps_title: "Commandez facilement en (3) étapes",
                    home_order_step1_title: "Choisissez un restaurant",
                    home_order_step1_sub_title: "Votre restaurant préféré n'est pas en ligne ? Recommandez-le !",
                    home_order_step2_title: "Choisissez vos plats préférés",
                    home_order_step2_sub_title: "Faites le choix entre d'excellents plats de votre restaurant préféré",
                    home_order_step3_title: "Choisissez pour collecter votre commande ou faites vous livrer",
                    home_order_step3_sub_title: "Certains restaurants disposent également de leur propre service de livraison. N'hésitez pas à en profiter",
                    home_order_payment_info: "Payez en ligne en toute sécurité grâce à nos options de paiement en ligne.",
                    home_trader_banner_title: "Commerçant ?",
                    home_trader_banner_info: "Êtes-vous un commerçant et souhaitez vous utiliser Eatsy la platforme en ligne?",
                    home_trader_banner_info_extension: "Cliquez ici.",
                    home_trader_banner_button_title: "Plus d'informations",
                },
                footer: {
                    site_application_info_title: "Saviez-vous que nous avons aussi une application?",
                    site_application_info_sub_title: "Commandez-vous souvent via mobile? téléchargez notre application. Découvrez la aussi bien pour IOS que pour Android",
                    title: "à propos de nous",
                    site_description: "Eatsy est une platforme en ligne ou les commerçants proposent leur produits à des prix avantageux, en utilisant Eatsy vous soutenez le secteur horeca, plus d'informations <a href='/contact'  rel='noreferrer'>ici</a>.",
                    trader_title: "Commerçant ?",
                    trader_info: "Êtes-vous un commerçant et souhaitez vous utiliser Eatsy la platforme en ligne?<a href='/contact'  rel='noreferrer'> Cliquez ici</a>.",
                    payment_options: "Options de paiement",
                    text_smart_solutions: "Smart Ordering Solutions",
                    text_contact: "Contact",
                    privacy_policy: "Privacy Policy",
                    cookie_statement: "Déclaration relative aux Cookies",
                    terms_and_conditions: "Conditions générales",
                },
                login: {
                    title: "Connectez-vous ou créez un compte",
                    email_label: "Adresse d'e-mail",
                    email_placeholder: "Adresse d'e-mail",
                    password_label: "Mot de passe",
                    password_placeholder: "Mot de passe",
                    validation_email_empty: "Entrez votre  e-mail.",
                    validation_email_not_valid: "Veuillez saisir une adresse d' email valide.",
                    validation_password_empty: "Veuillez saisir un mot de passe.",
                    button_login: "Se connecter",
                    navigate_register: "Pas de compte? <a href='#' className='registerlink'>Créez un compte.</a>",
                    navigate_forget_password: "Mot de passe oublié? <a href='#' className='registerlink'>Cliquez ici.</a>",
                },
                register: {
                    title: "Pas de compte",
                    label_firstName: "Prénom",
                    label_lastName: "Nom de famille",
                    label_email: "Email",
                    label_phone: "GSM",
                    label_street_and_house_number: "Adresse et numéro ( sera utilisé pour les livraisons)",
                    label_zipcode_and_municipality: "code postal & commune",
                    label_password: "Mot de passe",
                    label_confirm_password: "Confirmer le mot de passe",
                    button_navigation: "Compte éxistant?",
                    validation_firstname_empty: "Complétez votre prénom.",
                    validation_lastname_empty: "Complétez votre nom de famille.",
                    validation_email_empty: "Courriel ne peut etre vide.",
                    validation_email_not_valid: "Veuillez compléter un courriel valide.",
                    validation_phone_not_valid: "Veuillez compléter un numéro de téléphone valide.",
                    validation_password_length: "Mot de passe de min. 6 caractères.",
                    validation_confirm_password_equality: "Mots de passe non identiques.",
                    button_register: "enregistrez",
                    button_login: "Login",
                    validation_address_empty: "Complétez votre adresse.",
                    validation_city_state_empty: "Complétez commune.",
                },
                forgotPassword: {
                    title: "Complétez votre Email. Nous vous envoyons un lien de ré-initialisation",
                    button_send: "Envoyer",
                },
                menuBar: {
                    title_login: "Connectez-vous",
                    menu_title_logout: "déconnecter",
                    menu_title_previous_orders: "Commandes précedentes",
                    menu_title_profile: 'Profil'
                },
                restaurantDetail: {
                    restaurant_status_offline: "Horsligne",
                    restaurant_status_online: "En ligne",
                    restaurant_status_Open: "Ouvert",
                    restaurant_status_close: "Fermé",
                    text_no_category: "Pas de categorie",
                    text_select_option: "Sélectionnez une option.",
                    validation_greatedThenZero: "L'option séléctionnez doit être plus élevé que 0.",
                    left_sidebar_title: "Faites votre choix",
                    left_sidebar_menu_allProducts: "Tout les produits",
                    right_sidebar_title: "Commande actuelle",
                    right_sidebar_buttton_continue: "Continuer",
                    right_sidebar_text_total: "Totale",
                    openingHours: "Heures d 'ouverture",
                    askForService: "Demander un service",
                    askForInvoice: "Demander une facture",
                    requestAcceptMsg: 'Demande acceptée!',//remining,
                    somethingWrongMsg: 'Quelque-chose ne va pas!',//remining,
                    invoiceRequestMsg: 'Processus de demande de facture!',//remining,
                    selectOptionErrorMsg: "Veuillez sélectionner l'option en fonction du minimum et du maximum"
                },
                addItem: {
                    button_close_label: "Fermer",
                    button_order_now: "Commander maintenant",
                },
                cart: {
                    title: "Votre panier",
                    quantity: "Quantité",
                    total: "Totale",
                    cart_navigate_checkout: "Payer",
                    confirm_button_yes: "Oui",
                    confirm_button_close: "Fermer",
                    confirm_clear_cart: "Etes-vous sûr de supprimer votre panier",
                },
                checkout: {
                    title: "Payer",
                    navigate_login: '<span>Compléter le fichier ou </span><a href="#" ><span> connectez-vous pour continuer. </span></a>',
                    label_firstName: "Prénom",
                    label_lastName: "Nom de famille",
                    label_email: "E-mail",
                    label_phone: "Gsm",
                    quantity: "Quantité",
                    order_detail_title: "Votre commande",
                    label_deliveryFee: "Frais de livraison",
                    label_fixedCost: "Coûts fixes",
                    label_serviceCost: "Coût des services",
                    label_total: "Coûts totaux",
                    select_delivery_date: "Choisissez la date et l'heure",
                    payon_site_label: "Payer sur place ou à la livraison",
                    button_payOnline: "Payer en ligne",
                    button_payOnline_sub_text: "(Vous serez redirigé vers la plateforme de paiement après confirmation de la commande)",
                    delivery_option_title: "Choisissez livraison à domicile ou collectez.",
                    delivery_option_home: "Livraison à domicile",
                    delivery_option_pickUpOnSite: "Collectez sur place",
                    checkout_delivery_notprovide: "Le commerçant ne prévoit pas de livraison actuellement",
                    checkout_pickup_notprovide: "L'option de collecter n'est pas disponible",
                    postal_code: "Code postal et commune",
                    placeholder_postalcode: "Choisissez le code pin dans la liste déroulante",
                    street_address: "Rue et numéro",
                    comment: "Remarques",
                    placeholder_comment: "Mentionnez vos remarques concernant la livraison",
                    validation_firstname_empty: "Veuillez compléter votre prénom.",
                    validation_lastname_empty: "Veuillez compléter votre nom de famille.",
                    validation_email_empty: "Veuillez compléter votre e-mail.",
                    validation_email_not_valid: "Veuillez mentionner un e-mail valide.",
                    validation_phone_not_valid: "Veuillez mentionner un numéro de téléphone valide.",
                    validation_password_length: "Mot de passe de min. 6 caractères.",
                    validation_confirm_password_equality: "Mots de passe non identiques.",
                    register_button_register: "enregistrez",
                    register_button_login: "Login",
                    validation_delivery_date_empty: "Sélectionner date de livraison et l'heure.",
                    validation_pincode_empty: "Sélectionner code pin pour la livraison",
                    validation_order_type: "Veuillez sélectionner le type de livraison",
                    button_confirm_order: "Confirmez la commande",
                    orderPlacedMsg: 'Votre commande est passée avec succès!',//remaining 
                    DeliveryPickUpMessage: 'Les options de livraison et de ramassage sont disponibles par intervalle de temps',//remaining 
                    emptyDeliveryTime: "Vous n'avez pas sélectionné le délai de livraison!",//remaining ,
                    orderConfirmationText: "En cliquant sur ‘Confirmez la commande’, vous donnez votre accord pour le contenu de votre panier, les données fournies, notre politique de confidentialité et des conditions générales d'Eatsy et du Vendeur. Attention : aucune possibilité de rétractation n'est disponible.",
                    continueGuestCheckboxLabel: "Continuer en tant qu'invité",
                },
                previousOrder: {
                    title: "Commande précédente",
                    order_number: "Commande précédente",
                    order_status: "Status commande",
                    status_pending: "En attente",
                    status_accepted: "Accepté",
                    status_cancel: "Annulé",
                    date_label: "<span >Commandé </span>pour",
                    total_price: "Montant total",
                    button_reOrder: "Commandez à nouveau",
                    button_navigate_order_details: "Voir commande",
                    validation_delivery_date_empty: "Sélectionner date de livraison et l'heure",
                    no_prev_order: "Vous n'avez encore rien commandé."
                },
                orderAgain: {
                    title: "Choisir date / heure de livraison",
                    delivery_date_empty: "Choisir date / heure de livraison",
                    info_message: "Nous sommes actuellement fermé, Nous revenons bientôt",
                    confirm_message: "Etes-vous certain de vouloir recommander",
                    dropdown_placeholder: "Selectionner l'heure",
                    dropdown_empty: "Aucun article dans cette liste",
                    button_close: "Fermé",
                    button_submit: "Soumettre",
                },
                previousOrderDetail: {
                    title: "Details de la commande",
                    order_number: "Numéro de commande",
                    order_date: "Date de commande",
                    user_note: "Note utilisateur",
                    price: "Prix",
                    delivery_price: "livraison",
                    service_price: "Service",
                    total_price: "Total",
                    quantity: "Quantités",
                },
                page404: {
                    title: 'Page introuvable',
                    desc: "Malheureusement, la page que vous recherchez n'est pas trouvable",
                    navigate_home: "Retour page d'accueil"
                },
                weekday: {
                    mon: "lundi",
                    tue: "mardi",
                    wed: "mercredi",
                    thu: "jeudi",
                    fri: "vendredi",
                    sat: "samedi",
                    sun: "dimanche",
                },
                profile: {
                    title: 'Mettre à jour le profil',
                    validation: {
                        firstNameRequired: 'Veuillez entrer votre prénom',
                        lastNameRequired: 'Veuillez entrer votre nom de famille',
                        addressRequired: "Veuillez entrer l'adresse.",
                        phoneRequired: 'Veuillez entrer le numéro de téléphone.',
                        phoneInvalid: "S'il vous plait entrez un numéro de téléphone valide",
                        userUpdated: "Les informations utilisateur ont été mises à jour avec succès.",
                        userNotUpdated: "Impossible de mettre à jour les informations de l'utilisateur. Veuillez réessayer.",
                        userDelete: "L'utilisateur est supprimé.",
                        userNotDelete: "Impossible de supprimer cet utilisateur. Veuillez réessayer."
                    },
                    updateButtonLabel: 'Mise à jour',
                    deleteButtonLabel: 'Supprimer le compte',
                    firstNameLabel: 'Prénom',
                    firstNamePlaceholder: 'entrez votre prénom',
                    lastNameLabel: 'Nom de famille',
                    lastNamePlaceholder: 'Entrer le nom de famille',
                    phoneLabel: 'Téléphone',
                    phonePlaceholder: 'Entrez le numéro de téléphone',
                    addressLabel: 'Rue et numéro',
                    addressPlaceholder: 'abc, NY US',
                    pincodeLabel: 'Code postal et commune',
                    pincodePlaceholder: 'Choisissez le code postal dans la liste déroulante',
                    deleteConfirmDialog: {
                        cancelButtonLabel: "Annuler",
                        deleteButtonLabel: 'Supprimer le compte',
                        message: "Voulez-vous supprimer votre compte ?"
                    }
                },
                OrderReceipt: {
                    title1: 'Merci de votre commande',
                    title2: 'Votre paiement est en cours de vérification!',
                    description: "Vous recevrez bientôt une confirmation de votre commande dans votre boîte mail. Merci pour votre commande et à bientôt.",
                    NumberOfArticles: "Nombre d'articles #",
                    tableNumber: "Numéro de tableau"
                }
            }
        }
    },
    lng: "Dutch",
    fallbackLng: "Dutch",
    debug: true,
    interpolation: {
        escapeValue: false
    },
    initImmediate: false
}

export const languageAliases = {
    English: 'en',
    Dutch: 'nl',
    French: 'fr'
}

